// 決定大部分頁面的功能正常運作
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import { useLocation } from "react-router-dom"; // useHistory要使用在router內不然會出錯...
import { HashLink } from "react-router-hash-link";
import Menu from "./Menu";
import ICON_LOGO from "../images/LogoChaebol_Small.png";
import LanguageMenu from "./LanguageMenu";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    background: "initial",
    boxShadow: "none",
  },
  toolbar: {
    height: "72px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    padding: "0 24px 0 24px",
    justifyContent: "space-between",
    background: "#09080c99",
    gap: "48px",
    [theme.breakpoints.down("md")]: {
      height: "54px",
      minHeight: "54px",
      gap: "0",
    },
  },
  navSwitch: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  menuLinksButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
    flex: 3,
    // gap: "32px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& .item": {
      textDecoration: "none",
      color: theme.nftGray10,
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "143%",
      padding: "0 24px",
      "&.selected": {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "22px",
        color: theme.nftLightBlue,
      },
      "&:last-child": {
        margin: "0",
      },
    },
  },
  menuButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "24px",
  },
  logo: {
    flex: 1,
    height: "20px",
    minWidth: "184px",
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flex: 3,
    },
  },
  mobileMenu: {
    flex: 1,
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "24px",
    },
  },
}));

export default function Header(props) {
  let location = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const [accountOptions, setAccountOptions] = useState([]);

  const accountOptionsGenerate = () => {
    let result = [];
    result.push({
      display: "link",
      text: "nav.feature",
      to: "/#feature",
    });
    result.push({
      display: "link",
      text: "nav.roadmap",
      to: "/#roadmap",
    });
    result.push({
      display: "link",
      text: "nav.news",
      to: "/#news",
    });
    result.push({
      display: "link",
      text: "nav.whitePaper",
      to: "/#whitePaper",
    });
    result.push({
      display: "link",
      text: "nav.disclaimer",
      to: "/#disclaimer",
    });
    setAccountOptions(result);
  };

  const linkSelected = (path) => {
    let result = "";
    if (path === "/#feature" && location.hash === "#feature")
      result = "selected";
    if (path === "/#roadmap" && location.hash === "#roadmap")
      result = "selected";
    if (path === "/#news" && location.hash === "#news") result = "selected";
    if (path === "/#whitePaper" && location.hash === "#whitePaper")
      result = "selected";
    if (path === "/#disclaimer" && location.hash === "#disclaimer")
      result = "selected";

    return result;
  };

  useEffect(() => {
    accountOptionsGenerate();
  }, []);

  return (
    <AppBar position="sticky" className={classes.root}>
      <Toolbar className={classes.toolbar} disableGutters>
        <HashLink to="/#top" className={classes.logo}>
          <img src={ICON_LOGO} alt="" height="24" width="220" />
        </HashLink>
        <Box className={classes.mobileMenu}>
          <Menu />
        </Box>
        <div className={classes.menuLinksButtons}>
          {accountOptions.map((option, index) => (
            <HashLink
              key={index}
              className={`item ${linkSelected(option.to)}`}
              to={option.to}
            >
              {t(option.text)}
            </HashLink>
          ))}
        </div>
        <div className={classes.navSwitch}>
          <div className={classes.menuButtons}>
            <LanguageMenu setLanguage={props.setLanguage} />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}
