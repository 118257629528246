import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";

import IMAGE_TIMELINE_POINT from "../images/Timeline_Point.svg";
import IMAGE_DIAMOND from "../images/Diamond.svg";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "0 24px",
      zoom: 0.5,
    },
    "& .diamond": {
      top: "4px",
      right: "28px",
      position: "relative",
      zIndex: -1,
      // marginBottom:"-40px",
    },
    "& .MuiTimelineItem-alignAlternate:nth-child(even)": {
      "& .diamond": {
        left: "28px",
        right: "unset",
        position: "relative",
      },
    },
    "& .MuiTimelineSeparator-root":{
      [theme.breakpoints.down("sm")]: {
        margin: "0 24px",
      },
    }
  },
  time: {
    color: theme.nftGray10,
  },
  quarter: {
    color: theme.nftPurple,
  },
  tag: {
    color: theme.nftGray90,
    background: theme.nftLightGreen,
  },
  point: {
    //   marginTop: "24px"
  },
  diamondContainer: {
    height: 0,
  },
}));

export default function OppositeContentTimeline() {
  const classes = useStyles();
  const { t } = useTranslation();

  const data = [
    {
      title: "2021 ",
      quarter: "Q1",
      image: IMAGE_TIMELINE_POINT,
      tag: t("timeLineTag1"),
    },
    {
      title: "2021 ",
      quarter: "Q2",
      image: IMAGE_TIMELINE_POINT,
      tag: t("timeLineTag2"),
    },
    {
      title: "2021 ",
      quarter: "Q3",
      image: IMAGE_TIMELINE_POINT,
      tag: t("timeLineTag3"),
    },
    {
      title: "2021 ",
      quarter: "Q4",
      image: IMAGE_TIMELINE_POINT,
      tag: t("timeLineTag4"),
    },
    {
      title: "2022 ",
      quarter: "Q1",
      image: IMAGE_TIMELINE_POINT,
      tag: t("timeLineTag5"),
    },
    {
      title: "2022 ",
      quarter: "Q2",
      image: IMAGE_TIMELINE_POINT,
      tag: t("timeLineTag6"),
    },
    {
      title: "2023 ",
      quarter: "Q4",
      image: IMAGE_TIMELINE_POINT,
      tag: t("timeLineTag7"),
    },
  ];

  return (
    <React.Fragment>
      <Timeline align="alternate" className={classes.root}>
        {data.map((v, i) => (
          <TimelineItem key={i}>
            <TimelineOppositeContent>
              <Typography variant="subtitle1" className={classes.time}>
                <span>{v.title}</span>
                <span className={classes.quarter}>{v.quarter}</span>
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <img
                src={v.image}
                alt=""
                width="24"
                height="24"
                className={classes.point}
              />
              {data.length - 1 > i && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Box className={classes.diamondContainer}>
                <img src={IMAGE_DIAMOND} alt="" className="diamond" />
              </Box>
              <Typography variant="subtitle2">{v.tag}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </React.Fragment>
  );
}
