import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Divider, Drawer, IconButton } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import ICON_LOGO from "../images/LogoChaebol_Small.png";
import ICON_MENU from "../images/Menu.svg";
import ICON_CLOSE from "../images/Close.svg";
import ICON_ARROW_MORE from "../images/Arrow_More.svg";
import ICON_ARROW_LESS from "../images/Arrow_Less.svg";

const useStyles = makeStyles((theme) => ({
  menu: {
    // display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  title: {
    minHeight: "72px",
    height: "72px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px",
    "& .nft-text": {
      padding: "0",
    },
    [theme.breakpoints.down("md")]: {
      height: "54px",
      minHeight: "54px",
    },
  },
  logo: {
    height: "20px",
  },
  divider: {
    backgroundColor: "#302A3D",
    width: "calc(100% - 48px)",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      backgroundColor: theme.nftGray100,
      maxWidth: "414px",
      width: "100%",
      userSelect: "none",
      // marginTop: "83px", // 決定選單上方留下高度
    },
    "& .list": {
      width: "100%",
      backgroundColor: theme.nftGray100,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .item": {
        height: "44px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0 24px",
        width: "100%",
        border: 0,
        outline: "none",
        color: theme.nftGray10,
        background: "transparent",
        textDecoration: "none",
        fontFamily: "PingFang TC",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "22px",
        letterSpacing: "0.2px",
        userSelect: "none",
        cursor: "pointer",
        "&.selected": {
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "22px",
          color: theme.nftLightBlue,
          background: theme.nftGray90,
        },
        "&:hover": {
          // color: "#E8B802", // 選單滑過顏色
          background: "#9d9caf1a",
        },
        "&.language": {
          padding: "0 16px 0 40px",
        },
        "&.languageButtonIcon": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        },
        "&.desktopHidden": {
          [theme.breakpoints.up("lg")]: {
            display: "none",
          },
        },
      },
    },
  },
  userBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "12px",
    minHeight: "96px",
    width: "calc(100% - 48px)",
    background: theme.nftGray90,
    padding: "0 16px",
    margin: "0 24px 24px",
    cursor: "pointer",
  },
  userBlockImagesWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "32px",
    height: "64px",
    width: "64px",
    minWidth: "64px",
    background: "#302A3D",
  },
  userBlockImagesWrapExist: {
    height: "64px",
    width: "64px",
    borderRadius: "32px",
    background: theme.nftLightBlue,
    overflow: "hidden",
  },
  userBlockImagesWrapExistCustomize: {
    borderRadius: "32px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  desktopMenuAvatar: {
    height: "24px",
    width: "24px",
    borderRadius: "32px",
    background: theme.nftLightBlue,
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  desktopMenuAvatarWithoutAuth: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "32px",
    height: "24px",
    width: "24px",
    background: "#302A3D",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  desktopMenuAvatarCustomize: {
    borderRadius: "32px",
  },
  mobileMenuAvatar: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  userInfo: {
    marginLeft: "12px",
    color: theme.nftGray10,
  },
  authLoginButton: {
    margin: "16px",
  },
  saleButton: {
    borderRadius: "32px",
    padding: "0 24px",
    margin: "0 24px 16px 24px",
    height: "44px",
    minHeight: "44px",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // background: theme.nftGray70,
    // "&:hover": {
    //   background: theme.nftLightBlue,
    // },
  },
}));

export default function NFTExchangeMenu(props) {
  let location = useLocation();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [openLanguage, setOpenLanguage] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenLanguage = () => {
    setOpenLanguage(!openLanguage);
  };

  const languages = {
    "zh-TW": "繁體中文",
    "zh-CN": "简体中文",
    en: "English",
  };

  const toggleDrawer = () => {
    setOpenMenu(!openMenu);
  };

  const accountOptionsGenerate = () => {
    let result = [];
    result.push({
      display: "link",
      text: "nav.feature",
      to: "/#feature",
    });
    result.push({
      display: "link",
      text: "nav.roadmap",
      to: "/#roadmap",
    });
    result.push({
      display: "link",
      text: "nav.news",
      to: "/#news",
    });
    result.push({
      display: "link",
      text: "nav.whitePaper",
      to: "/#whitePaper",
    });
    result.push({
      display: "link",
      text: "nav.disclaimer",
      to: "/#disclaimer",
    });
    setAccountOptions(result);
  };

  const linkSelected = (path) => {
    let result = "";
    if (path === "/#feature" && location.hash === "#feature")
      result = "selected";
    if (path === "/#roadmap" && location.hash === "#roadmap")
      result = "selected";
    if (path === "/#news" && location.hash === "#news") result = "selected";
    if (path === "/#whitePaper" && location.hash === "#whitePaper")
      result = "selected";
    if (path === "/#disclaimer" && location.hash === "#disclaimer")
      result = "selected";
    return result;
  };

  useEffect(() => {
    accountOptionsGenerate();
  }, []);

  return (
    <div className={classes.menu}>
      <IconButton
        onClick={() => {
          setOpenMenu(true);
        }}
      >
        <img
          className={classes.mobileMenuAvatar}
          src={ICON_MENU}
          alt="menu"
          height="24"
          width="24"
        />
      </IconButton>
      <Drawer
        anchor={"right"}
        open={openMenu}
        onClose={toggleDrawer}
        className={classes.drawer}
      >
        <div className={classes.title}>
          <HashLink to="/#top" className={classes.logo} onClick={toggleDrawer}>
            <img src={ICON_LOGO} alt="" height="24" width="220" />
          </HashLink>
          <IconButton onClick={toggleDrawer}>
            <img src={ICON_CLOSE} alt="menu" height="24" width="24" />
          </IconButton>
        </div>

        <div className="list">
          {accountOptions.map((option, index) => (
            <HashLink
              key={index}
              className={`item desktopHidden ${linkSelected(option.to)}`}
              to={option.to}
              onClick={toggleDrawer}
            >
              {t(option.text)}
            </HashLink>
          ))}
          <Divider className={classes.divider} />
          <button
            type="button"
            className="item desktopHidden languageButtonIcon"
            onClick={handleOpenLanguage}
          >
            <span>{t("menuLanguages")}</span>
            <img
              src={openLanguage ? ICON_ARROW_MORE : ICON_ARROW_LESS}
              alt="menu"
              height="24"
              width="24"
            />
          </button>
          {openLanguage &&
            Object.keys(languages).map((v, i) => (
              <button
                type="button"
                className="item desktopHidden language"
                key={i}
                onClick={() => {
                  i18n.changeLanguage(v);
                  window.localStorage.setItem("language", v);
                }}
              >
                {languages[v]}
              </button>
            ))}
        </div>
      </Drawer>
    </div>
  );
}
