import React, { useEffect, useState, Suspense } from "react";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./components/Header";
import Loading from "./components/Loading";
import { useTranslation } from "react-i18next";
import { Divider, Box, Typography } from "@material-ui/core";
import Timeline from "./components/Timeline";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import IMAGE_LOGO_CHAEBOL_LARGE from "./images/LogoChaebol_Large.png";
import IMAGE_LOGO_CHAEBOL_SMALL from "./images/LogoChaebol_Small.png";
import IMAGE_LOGO_CHAEBOL_OUTLINE from "./images/Logo_Chaebol_Outline.svg";
import IMAGE_LOGO_CHAEBOL_BG from "./images/LogoChaebol_Bg.png";

import IMAGE_POTATO_MEDIA from "./images/PotatoMedia.png";
import IMAGE_BG from "./images/Bg.svg";
import IMAGE_BUYER from "./images/Buyer.png";
import IMAGE_ARTIST from "./images/Artist.png";
import IMAGE_COLLECTOR from "./images/Collector.png";
import IMAGE_INVESTOR from "./images/Investor.png";
import IMAGE_PHOTO_A from "./images/PhotoA.png";
import IMAGE_PHOTO_B from "./images/PhotoB.png";
import IMAGE_PHOTO_C from "./images/PhotoC.png";

import IMAGE_ART_EXPERT from "./images/ArtExpert.png";
import IMAGE_CHAEBOL from "./images/Chaebol.png";
import IMAGE_ARTOWNER from "./images/ArtOwner.png";
import IMAGE_ART_VAULT from "./images/ArtVault.png";

import IMAGE_ARROW_D from "./images/ArrowD.svg";
import IMAGE_ARROW_L from "./images/ArrowL.svg";
import IMAGE_ARROW_R from "./images/ArrowR.svg";
import IMAGE_ARROW_U from "./images/ArrowU.svg";

import { ReactComponent as ICON_DOWNLOAD } from "./images/Download.svg";

// function getOS() {
//   var userAgent = window.navigator.userAgent,
//     platform = window.navigator.platform,
//     macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
//     windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
//     iosPlatforms = ["iPhone", "iPad", "iPod"],
//     os = null;
//   if (macosPlatforms.indexOf(platform) !== -1) {
//     os = "Mac OS";
//   } else if (iosPlatforms.indexOf(platform) !== -1) {
//     os = "iOS";
//   } else if (windowsPlatforms.indexOf(platform) !== -1) {
//     os = "Windows";
//   } else if (/Android/.test(userAgent)) {
//     os = "Android";
//   } else if (!os && /Linux/.test(platform)) {
//     os = "Linux";
//   }
//   return os;
// }

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Mulish"', //   自定義主題的字體擺放位置與順序
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "48px",
      fontWeight: "normal",
      lineHeight: "133%",
      letterSpacing: "0.3em",
    }, // 因為letterSpacing 會影響最後面有空間就不會再正中央
    h2: {
      fontSize: "22px",
      fontWeight: "normal",
      lineHeight: "130%",
      letterSpacing: "2em",
    },
    h3: {
      fontSize: "34px",
      fontWeight: "normal",
      lineHeight: "130%",
      letterSpacing: "0.0025em",
    },
    h4: { fontSize: "34px", fontWeight: "600", lineHeight: "130%" },
    h5: { fontSize: "24px", fontWeight: "bold", lineHeight: "132%" },
    h6: { fontSize: "20px", fontWeight: "bold", lineHeight: "138%" },
    subtitle1: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "132%",
      fontStyle: "italic",
    },
    subtitle2: { fontSize: "22px", fontWeight: "400", lineHeight: "131%" },
    body1: { fontSize: "20px", fontWeight: "400", lineHeight: "138%" },
    body2: { fontSize: "16px", fontWeight: "700", lineHeight: "143%" },
    body3: { fontSize: "16px", fontWeight: "400", lineHeight: "150%" },
    body4: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "150%",
      letterSpacing: "0.005em",
    },
    button: { fontSize: "16px", fontWeight: "bold", lineHeight: "142%" },
    caption: { fontSize: "12px", fontWeight: "normal", lineHeight: "130%" },
    overline: { fontSize: "10px", fontWeight: "normal", lineHeight: "158%" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024, // 中間段需要另外花時間設定RWD細節
      lg: 1440,
      xl: 1920,
    },
  },
  overrides: {
    MuiTypography: {
      // root:{
      //   fontFamily: "'Exo 2'",
      //   fontSize: "14px",
      //   fontStyle: "normal",
      //   fontWeight: "400",
      //   lineHeight: "20px",
      //   letterSpacing: "0.0025em",
      //   textAlign: "center",
      // }
    },
    MuiSnackbar: {
      root: {
        background: "#302A3D",
        padding: "16px",
        borderRadius: "12px",
        gap: "12px",
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiButton: {
      root: {
        "&$disabled": {
          opacity: 0.3,
          color: "#ffffff",
        },
      },
      text: {
        fontSize: "1rem",
        padding: 0,
        margin: "8px",
        borderRadius: "0",
        // color: "#5CC6FF",
        "& .nftGradientText": {
          background: "linear-gradient(270deg, #3E78FF 0%, #5CC6FF 100%)",
          "-webkit-background-clip": "text",
          // "-webkit-text-fill-color": "linear-gradient(270deg, #3E78FF 0%, #5CC6FF 100%)",
          color: "transparent",
          wordBreak: "keep-all",
        },
      },
      contained: {
        borderRadius: "100px",
        color: "#ffffff",
        height: "48px",
        background: "linear-gradient(270deg, #3e78ff 0%, #5cc6ff 100%)",
      },
      outlined: {},
      // label: {
      //   background: "linear-gradient(270deg, #3E78FF 0%, #5CC6FF 100%)",
      //   "-webkit-background-clip": "text",
      //   // "-webkit-text-fill-color": "linear-gradient(270deg, #3E78FF 0%, #5CC6FF 100%)",
      //   color: "transparent",
      // },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#1C1924",
      },
    },
    MuiAccordion: {
      root: {
        "&.Mui-expanded": {
          margin: 0,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        minHeight: "unset",
        alignItems: "flex-start",
        "&.Mui-expanded": {
          minHeight: "unset",
        },
      },
      content: {
        margin: 0,
        "&.Mui-expanded": {
          margin: 0,
        },
      },
      expandIcon: {
        margin: 0,
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: "24px 0 0 0",
      },
    },
    MuiList: {
      root: {
        background: "#1C1924",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1rem",
        padding: "0",
        margin: "0",
        // margin: "8px 0",
        width: "100%",
        border: "2px solid #302A3D",
        borderRadius: "48px" /* 至少兩倍 */,
        color: "#ffffff",
        height: "44px",
        "&$focused": {
          // 偽類定義方式  https://material-ui.com/zh/customization/components/
          border: "2px solid transparent" /* 相對低 */,
          backgroundImage:
            "linear-gradient(#09080c, #09080c), linear-gradient(270deg, #3E78FF 0%, #5CC6FF 100%)",
          backgroundOrigin: "border-box",
          backgroundClip: "content-box, border-box",
        },
      },
      input: {
        paddingLeft: "20px",
      },
    },
    MuiInputLabel: {
      root: { color: "#ffffff", marginBottom: "8px" },
    },
    MuiInputAdornment: {
      root: { color: "#ffffff" },
      positionEnd: { marginRight: "16px" },
    },
    MuiFormHelperText: {
      root: {
        marginTop: "8px",
        color: "#FE4460",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "130%",
      },
    },
    MuiCircularProgress: { colorPrimary: "#5CC6FF" },
    MuiDialog: {
      // 對話框設定
      paperFullScreen: {
        height: "unset",
        borderTopLeftRadius: "24px",
        borderTopRightRadius: "24px",
      },
      paper: {
        background: "#1C1924",
        borderRadius: "12px",
      },
    },
    MuiTimeline: {
      root: {
        padding: 0,
      },
    },
    MuiTimelineItem: {
      root: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: "unset",
      },
      alignAlternate: {
        "&:nth-child(even)": {
          // 裝飾菱形
        },
      },
    },
    MuiTimelineConnector: {
      root: {
        background: "linear-gradient(0deg, #BC85FF, #BC85FF)",
        width: "4px",
      },
    },
    MuiTimelineContent: {
      root: {
        background: "#85FFC4",
        // minWidth: "600px",
        padding: "12px 16px",
        marginTop: "-16px",
        // whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
    },
    MuiTimelineOppositeContent: {
      root: {
        // minWidth: "600px",
        padding: "12px 16px",
        marginTop: "-16px",
        // whiteSpace: "nowrap",
        wordBreak: "keep-all",
      },
    },
    MuiTimelineSeparator: {
      root: {
        display: "flex",
        flexDirection: "column",
        margin: "0 56px",
        height: "124px",
      },
    },
  },
  nftGray100: "#09080C",
  nftGray90: "#1C1924",
  nftGray70: "#302A3D",
  nftGray50: "#686173",
  nftGray30: "#E9E7EE",
  nftGray10: "#FFFFFF",
  nftBackground: "#09080C",
  nftContentText: "#E9E7EE",
  nftButtonText: "#FFFFFF",
  nftLightBlue: "#5CC6FF",
  nftBlue: "#3E78FF",
  nftGreen: "#02CE92",
  nftLightGreen: "#85FFC4",
  nftRed: "#FE4460",
  nftPurple: "#BC85FF",
});

const useStyles = makeStyles((theme) => ({
  "@keyframes showAnimation": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@keyframes spaceAnimation": {
    "0%": {
      opacity: 0,
      letterSpacing: "8em",
      paddingLeft: "8em",
      transform: "scale(0)",
    },
    "100%": {
      opacity: 1,
      letterSpacing: "2em",
      paddingLeft: "2em",
      transform: "scale(1)",
    },
  },
  "@keyframes spaceAnimationEn": {
    "0%": {
      opacity: 0,
      letterSpacing: "3em",
      paddingLeft: "3em",
    },
    "100%": {
      opacity: 1,
      letterSpacing: "0.2em",
      paddingLeft: "0.2em",
    },
  },
  "@keyframes easeInFromBottomAnimation": {
    "0%": {
      opacity: 0,
      transform: "translate(0, 100px)",
    },
    "100%": {
      opacity: 1,
      transform: "translate(0, 0)",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    // background: "#ffffff", // 測試用的,
    background: "#09080C",
    // overflow: "hidden",
  },
  limitWidthContainer: {
    maxWidth: "1168px",
  },
  backgroundContainer: {
    width: "100%",
    overflow: "hidden",
    position: "absolute",
    // height: "6158px",
    zIndex: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      opacity: "0.7",
    },
  },
  bannerBackground: {
    zIndex: "0",
    position: "absolute",
    height: "1259px",
    width: "1336px",
    transform: "translate(-194px, -634px)",
  },
  whoShouldUseBackground: {
    height: "1259px",
    width: "1336px",
    transform: "rotate(90deg) translate(500px, -500px)",
  },
  developBackground: {
    height: "1259px",
    width: "1336px",
    margin: "2500px 0 0 0",
    transform: "rotate(225deg) translate(424px, 0)",
  },
  newsBackground: {
    height: "1259px",
    width: "1336px",
    margin: "0 0 0 0",
    transform: "rotateY(180deg) rotateZ(270deg) translate(120px, -612px)",
  },
  banner: {
    zIndex: 1,
    color: theme.nftGray10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "650px",
    minHeight: "417px",
    width: "100%",
    background: "initial",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  bannerLogo: {
    marginTop: "12px",
    animationName: "$showAnimation", // 使用$帶入變數
    animationDuration: "6s",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      width: "285px",
      height: "31px",
    },
  },
  slogan: {
    marginTop: "12px",
    animationName: "$showAnimation", // 使用$帶入變數
    animationDuration: "6s",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down("xs")]: {
      whiteSpace: "normal",
      textAlign: "center",
      // letterSpacing: "0",
    },
  },
  subSlogan: {
    marginTop: "36px",
    animationName: "$spaceAnimation", // 使用$帶入變數
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    animationDuration: "6s",
    letterSpacing: "2em",
    paddingLeft: "2em",
    textAlign: "center",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      whiteSpace: "normal",
      letterSpacing: "2em",
      paddingLeft: "2em",
    },
  },
  subSloganSpaceAnimationEn: {
    marginTop: "36px",
    animationName: "$spaceAnimationEn", // 使用$帶入變數
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    animationDuration: "6s",
    letterSpacing: "0.2em",
    paddingLeft: "0.2em",
    textAlign: "center",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      whiteSpace: "normal",
      animationName: "$easeInFromBottomAnimation", // 使用$帶入變數
    },
  },
  bannerButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    animationName: "$easeInFromBottomAnimation", // 使用$帶入變數
    marginTop: "32px",
    transition: "all 2s",
    animationDuration: "6s",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  bannerButton: {
    minWidth: "216px",
    padding: "0 24px",
    // zIndex: "1",
    borderRadius: "100px",
    color: "#ffffff",
    height: "48px",
    background: "linear-gradient(270deg, #3e78ff 0%, #5cc6ff 100%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    textTransform: "normal",
    margin: "0 8px",
    [theme.breakpoints.down("xs")]: {
      margin: "6px",
    },
  },
  testnetsLink: {
    background: theme.nftLightGreen,
    color: theme.nftGray100,
    minWidth: "216px",
    padding: "0 24px",
    // zIndex: "1",
    borderRadius: "100px",
    height: "48px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    textTransform: "normal",
    margin: "0 8px",
    [theme.breakpoints.down("xs")]: {
      margin: "6px",
    },
  },
  bannerDescription:{
    animationName: "$easeInFromBottomAnimation", // 使用$帶入變數
    transition: "all 2s",
    animationDuration: "6s",
    marginTop: "32px",
  },
  whoShouldUseContainer: {
    width: "100%",
    // height: "780px",
    padding: "94px 28px 110px 28px",
    color: theme.nftGray10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#09080cb3",
    zIndex: "1",
    [theme.breakpoints.down("xs")]: {
      padding: "60px 28px 52px 28px",
    },
  },
  whoShouldUseTitle: {
    margin: "0 0 94px 0",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 36px 0",
    },
  },
  whoShouldUseItems: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "56px",
    // [theme.breakpoints.down("sm")]: {
    //   flexWrap: "unset",
    // },
  },
  whoShouldUseItem: {
    width: "calc((100% - 56px)/2)",
    display: "flex",
    flexDirection: "row",
    gap: "36px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  whoShouldUseTextColor1: { color: theme.nftLightGreen },
  whoShouldUseTextColor2: { color: theme.nftLightBlue },
  whoShouldUseTextColor3: { color: theme.nftBlue },
  whoShouldUseTextColor4: { color: theme.nftPurple },
  whoShouldUseItemText: {},
  whoShouldUseItemTextTitle: {
    marginBottom: "12px",
  },
  whoShouldUseItemTextSubTitle: {},
  whoShouldUseItemTextContent: {
    marginTop: "24px",
  },
  feature: {
    color: theme.nftGray10,
    width: "100%",
    maxWidth: "1168px",
    zIndex: 1,
  },
  featureTitle: {
    margin: "94px 0",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "60px 0 36px 0",
    },
  },
  featureItem: {
    width: "100%",
    maxWidth: "1168px",
    minHeight: "340px",
    display: "flex",
    flexDirection: "row",
    marginBottom: "80px",
    justifyContent: "flex-end",
    backgroundRepeat: "no-repeat",
    backgroundSize: "530px 340px",
    backgroundPosition: "left center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      backgroundSize: "327px 210px",
      // marginBottom: "0",
      padding: "0 24px",
      // alignItems:"center"
      backgroundPosition: "top center !important",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "60px 0 36px 0",
    },
  },
  featureItemRight: {
    justifyContent: "flex-start",
  },
  featureTextWrap: {
    display: "flex",
    flexDirection: "column",
    // zIndex:"1",
    margin: "0 0 0 -90px",
    maxWidth: "728px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
      margin: 0,
    },
  },
  featureTextWrapRight: {
    margin: "0 -90px 0 0",
    [theme.breakpoints.down("sm")]: {
      // flexDirection: "column",
      margin: 0,
    },
  },
  featureTextTitle: {
    margin: "170px 0 24px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "240px 0 24px 0",
    },
  },
  featureTextContent: {},
  featureTextColor1: { color: theme.nftLightGreen },
  featureTextColor2: { color: theme.nftLightBlue },
  featureTextColor3: { color: theme.nftPurple },
  logic: {
    zIndex: 1,
    color: theme.nftGray10,
    margin: "94px 0",
    width: "100%",
    background: "#09080cb3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logicLimitContainer: {
    maxWidth: "1168px",
    padding: "94px 0 136px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "36px 0 60px 0",
    },
  },
  logicTitle: {
    color: theme.nftGray10,
    textAlign: "center",
    marginBottom: "16px",
  },
  logicImagesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      zoom: 0.5,
    },
  },
  logicImagesItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "& > *": {
      flex: 1,
      margin: "0 24px",
    },
  },
  logicImagesImage: {
    [theme.breakpoints.down("sm")]: {
      height: "140px",
      width: "122px",
    },
  },
  logicImagesItemMedium: {
    margin: "-20px 0 -20px 0",
  },
  logicTextColor: {
    color: theme.nftLightGreen,
    [theme.breakpoints.down("sm")]: {},
  },
  develop: {
    zIndex: 1,
  },
  developDecoration: {
    zIndex: 1,
    position: "absolute",
    transform: "translate(-300px, -288px)",
  },
  developTitle: {
    textAlign: "center",
    color: theme.nftGray10,
    margin: "94px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "60px 0 36px 0",
    },
  },
  news: {
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "480px",
    // maxWidth:"1168px",
    width: "100%",
    background:
      "linear-gradient(180deg, rgba(28, 25, 36, 0.7) -0.68%, rgba(28, 25, 36, 0) 100%);",
  },
  newsTitle: { color: theme.nftGray10, marginBottom: "74px" },
  newsText1: { color: theme.nftLightGreen, marginTop: "24px" },
  newsText2: { color: theme.nftGray10, marginTop: "12px" },
  whitePaper: {
    zIndex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.nftGray10,
  },
  whitePaperLimitWidthContainer: {
    maxWidth: "1168px",
    width: "100%",
    height: "152px",
    backgroundImage: `url(${IMAGE_LOGO_CHAEBOL_BG})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "center center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  whitePaperTitle: {
    marginTop: "12px",
  },
  downloadButtons: {
    zIndex: 1,
    marginTop: "64px",
    // height: "72px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: 0,
    },
  },
  downloadButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    height: "44px",
    minWidth: "172px",
    color: theme.nftGray10,
    textDecoration: "none",
    padding: 0,
    // margin: "8px",
    border: "2px solid transparent",
    borderRadius: "48px" /* 至少兩倍 */,
    backgroundImage:
      "linear-gradient(#09080c, #09080c), linear-gradient(74.31deg, #85ffc4 0%, #5cc6ff 45.83%, #bc85ff 80.73%)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    cursor: "pointer",
  },
  disclaimer: {
    padding: "94px 0 80px 0",
    background: theme.nftGray100,
    [theme.breakpoints.down("sm")]: {
      padding: "108px 0 80px 0",
    },
  },
  disclaimerTitle: { color: theme.nftGray10, marginBottom: "54px" },
  disclaimerContent: {
    color: theme.nftGray10,
    textAlign: "center",
    padding: "0 24px",
  },
  footerDivider: { background: theme.nftGray90, width: "100%" },
  footer: {
    color: theme.nftGray50,
    width: "100%",
    height: "72px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    letterSpacing: "0.005em",
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

function BackgroundBlock() {
  const classes = useStyles();
  return (
    <Box className={classes.backgroundContainer}>
      <img src={IMAGE_BG} alt="" className={classes.bannerBackground} />
      <img src={IMAGE_BG} alt="" className={classes.whoShouldUseBackground} />
      <img src={IMAGE_BG} alt="" className={classes.developBackground} />
      <img src={IMAGE_BG} alt="" className={classes.newsBackground} />
    </Box>
  );
}

function Banner() {
  const classes = useStyles();
  const { t } = useTranslation();
  const language = window.localStorage.getItem("language") || "zh-TW";

  return (
    <Box id="top" className={classes.banner}>
      <img
        src={IMAGE_LOGO_CHAEBOL_LARGE}
        alt=""
        width="auto"
        height="68"
        className={classes.bannerLogo}
      />
      <Typography variant="h1" className={classes.slogan}>
        {t("slogan")}
      </Typography>
      <Typography
        variant="h4"
        align="center"
        className={
          language !== "en"
            ? classes.subSlogan
            : classes.subSloganSpaceAnimationEn
        }
      >
        {t("subSlogan")}
      </Typography>
      <Box className={classes.bannerButtons}>
        <a target="_blank"  rel="noreferrer" href="https://chaebol.art" className={classes.bannerButton}>
          {t("enterChaebol")}
        </a>
        <a target="_self" href="#whitePaper" className={classes.bannerButton}>
          {t("downloadWhitePaper")}
        </a>
        {/* <a
          className={classes.testnetsLink}
          href="https://chaebol.art/welcome"
          target="_blank"
          rel="noreferrer"
        >
          {t("testnets")}
        </a> */}
      </Box>
      {/* <Typography variant="body1" align="center"  className={classes.bannerDescription}>
      {t("5月20日 正式開站")}
      </Typography> */}
    </Box>
  );
}

function WhoShouldUse() {
  const classes = useStyles();
  const { t } = useTranslation();
  const data = [
    {
      image: IMAGE_BUYER,
      color: classes.whoShouldUseTextColor1,
      title: t("whoShouldUse.title1"),
      subTitle: t("whoShouldUse.subTitle1"),
      content: t("whoShouldUse.content1"),
    },
    {
      image: IMAGE_ARTIST,
      color: classes.whoShouldUseTextColor2,
      title: t("whoShouldUse.title2"),
      subTitle: t("whoShouldUse.subTitle2"),
      content: t("whoShouldUse.content2"),
    },
    {
      image: IMAGE_COLLECTOR,
      color: classes.whoShouldUseTextColor3,
      title: t("whoShouldUse.title3"),
      subTitle: t("whoShouldUse.subTitle3"),
      content: t("whoShouldUse.content3"),
    },
    {
      image: IMAGE_INVESTOR,
      color: classes.whoShouldUseTextColor4,
      title: t("whoShouldUse.title4"),
      subTitle: t("whoShouldUse.subTitle4"),
      content: t("whoShouldUse.content4"),
    },
  ];

  return (
    <Box className={classes.whoShouldUseContainer}>
      <Box className={classes.limitWidthContainer}>
        <Typography variant="h3" className={classes.whoShouldUseTitle}>
          {t("whoShouldUseTitle")}
        </Typography>
        <Box className={classes.whoShouldUseItems}>
          {data.map((v, i) => (
            <Box className={classes.whoShouldUseItem} key={i}>
              <img src={v.image} alt="" height="85" width="85" />
              <Box className={classes.whoShouldUseItemText}>
                <Typography
                  variant="h4"
                  className={clsx(v.color, classes.whoShouldUseItemTextTitle)}
                >
                  {v.title}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={clsx(
                    v.color,
                    classes.whoShouldUseItemTextSubTitle
                  )}
                >
                  {v.subTitle}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.whoShouldUseItemTextContent}
                >
                  {v.content}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function Feature() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box id="feature" className={classes.feature}>
      <Typography variant="h3" className={classes.featureTitle}>
        {t("featureTitle")}
      </Typography>
      <Box
        className={classes.featureItem}
        style={{ backgroundImage: `url(${IMAGE_PHOTO_A})` }}
      >
        <Box className={classes.featureTextWrap}>
          <Typography
            variant="h4"
            className={clsx(
              classes.featureTextTitle,
              classes.featureTextColor1
            )}
          >
            {t("featureTitle1")}
          </Typography>
          <Typography variant="subtitle2" className={classes.featureContent}>
            {t("featureContent1")}
          </Typography>
        </Box>
      </Box>
      <Box
        className={clsx(classes.featureItem, classes.featureItemRight)}
        style={{
          backgroundImage: `url(${IMAGE_PHOTO_B})`,
          backgroundPosition: "right center",
        }}
      >
        <Box
          className={clsx(
            classes.featureTextWrap,
            classes.featureTextWrapRight
          )}
        >
          <Typography
            variant="h4"
            className={clsx(
              classes.featureTextTitle,
              classes.featureTextColor2
            )}
          >
            {t("featureTitle2")}
          </Typography>
          <Typography variant="subtitle2" className={classes.featureContent}>
            {t("featureContent2")}
          </Typography>
        </Box>
      </Box>
      <Box
        className={classes.featureItem}
        style={{ backgroundImage: `url(${IMAGE_PHOTO_C})` }}
      >
        <Box className={classes.featureTextWrap}>
          <Typography
            variant="h4"
            className={clsx(
              classes.featureTextTitle,
              classes.featureTextColor3
            )}
          >
            {t("featureTitle3")}
          </Typography>
          <Typography variant="subtitle2" className={classes.featureContent}>
            {t("featureContent3")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function Logic() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.logic}>
      <Box className={classes.logicLimitContainer}>
        <Typography variant="h3" className={classes.logicTitle}>
          {t("logicTitle")}
        </Typography>
        <Box className={classes.logicImagesContainer}>
          <Box className={classes.logicImagesItem}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src={IMAGE_ARTOWNER}
                alt=""
                width="244"
                height="280"
                className={classes.logicImagesImage}
              />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography
                variant="h4"
                align="center"
                className={classes.logicTextColor}
              >
                {t("logicText1")}
              </Typography>
              <img src={IMAGE_ARROW_R} alt="" width="300" height="18" />
              <Box>
                <br />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src={IMAGE_CHAEBOL}
                alt=""
                width="244"
                height="280"
                className={classes.logicImagesImage}
              />
            </Box>
          </Box>

          <Box
            className={clsx(
              classes.logicImagesItem,
              classes.logicImagesItemMedium
            )}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
              flex="1"
            >
              <Typography
                variant="h4"
                align="right"
                className={classes.logicTextColor}
              >
                {t("logicText2")}
              </Typography>
              <img src={IMAGE_ARROW_D} alt="" width="18" height="150" />
            </Box>
            <Box></Box>
            <Box>
              <img src={IMAGE_ARROW_U} alt="" width="18" height="150" />
            </Box>
          </Box>

          <Box className={classes.logicImagesItem}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src={IMAGE_ART_VAULT}
                alt=""
                width="244"
                height="280"
                className={classes.logicImagesImage}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <br />
              </Box>
              <img src={IMAGE_ARROW_L} alt="" width="300" height="18" />
              <Typography
                variant="h4"
                align="center"
                className={classes.logicTextColor}
              >
                {t("logicText3")}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src={IMAGE_ART_EXPERT}
                alt=""
                width="244"
                height="280"
                className={classes.logicImagesImage}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Develop() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box id="roadmap" className={classes.develop}>
      <img
        src={IMAGE_LOGO_CHAEBOL_OUTLINE}
        alt=""
        width="100"
        height="917"
        className={classes.developDecoration}
      />
      <Typography variant="h3" className={classes.developTitle}>
        {t("developTitle")}
      </Typography>
      <Timeline />
    </Box>
  );
}

function News() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box id="news" className={classes.news}>
      <Typography variant="h4" className={classes.newsTitle}>
        {t("newsTitle")}
      </Typography>
      <img src={IMAGE_POTATO_MEDIA} alt="" width="220" height="92" />
      {/* <Typography variant="h5" className={classes.newsText1}>
        {t("newsText1")}
      </Typography>
      <Typography variant="body2" className={classes.newsText2}>
        {t("newsText2")}
      </Typography> */}
    </Box>
  );
}

function WhitePaper() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Box id="whitePaper" className={classes.whitePaper}>
      <Box className={classes.whitePaperLimitWidthContainer}>
        <img src={IMAGE_LOGO_CHAEBOL_SMALL} alt="" width="220" height="24" />
        <Typography variant="h4" className={classes.whitePaperTitle}>
          {t("whitePaper")}
        </Typography>
      </Box>
    </Box>
  );
}

function Downloads() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Box className={classes.downloadButtons}>
      <a
        href="./docs/CHAEBOL_zh-TW.pdf"
        download
        target="_blank"
        className={classes.downloadButton}
        rel="noreferrer"
      >
        <ICON_DOWNLOAD />
        <Typography variant="button">繁體中文</Typography>
      </a>
      <a
        href="./docs/CHAEBOL_zh-CN.pdf"
        download
        target="_blank"
        className={classes.downloadButton}
        rel="noreferrer"
      >
        <ICON_DOWNLOAD />
        <Typography variant="button">简体中文</Typography>
      </a>
      <a
        href="./docs/CHAEBOL_en.pdf"
        download
        target="_blank"
        className={classes.downloadButton}
        rel="noreferrer"
      >
        <ICON_DOWNLOAD />
        <Typography variant="button">ENGLISH</Typography>
      </a>
    </Box>
  );
}

function Disclaimer() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Box id="disclaimer" className={classes.disclaimer}>
      <Box className={classes.limitWidthContainer}>
        <Typography
          variant="h4"
          align="center"
          className={classes.disclaimerTitle}
        >
          {t("disclaimerTitle")}
        </Typography>
        <Typography variant="body1" className={classes.disclaimerContent}>
          {t("disclaimerContent")}
        </Typography>
      </Box>
    </Box>
  );
}

function Footer() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const year = new Date().getFullYear();
  return (
    <>
      <Divider className={classes.footerDivider}></Divider>
      <Box className={classes.footer}>
        <Typography>{`COPYRIGHT © ${year} CHAEBOL. ALL RIGHTS RESERVED.`}</Typography>
      </Box>
    </>
  );
}

function Layout() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(
    window.localStorage.getItem("language") || "zh-TW"
  );

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <Suspense fallback={<Loading />}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <Router>
            <Switch>
              <Route exact path="/">
                <Header />
                <Banner />
                <WhoShouldUse />
                <Feature />
                <Logic />
                <Develop />
                <News />
                <WhitePaper />
                <Downloads />
                <Disclaimer />
                <Footer />
                <BackgroundBlock />
              </Route>
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    </Suspense>
  );
}

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Layout />
    </Suspense>
  );
}

export default App;
