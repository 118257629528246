import React, { useState } from "react";
import { Box, Button, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  languageButton: {
    color: "#ffffff",
    padding: "0",
    fontFamily: "PingFang TC",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0.8px",
    textTransform: "capitalize",
    margin: "0",
    minWidth: "auto",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "&:hover": {
      color: theme.nftLightBlue,
    },
    "&.dark": {
      color: "#0B0817",
    },
  },
  menu: {
    "& .MuiList-root": {
      background: theme.nftGray100,
      "& .MuiMenuItem-root": {
        color: "#ffffff",
        "&:hover": {
          color: theme.nftLightBlue,
        },
      },
    },
    "&.dark": {
      "& .MuiList-root": {
        background: "#F5F7FA",
        "& .MuiMenuItem-root": {
          color: "#0B0817",
          "&:hover": {
            color: "#E8B802",
          },
        },
      },
    },
  },
}));

export default function LanguageMenu(props) {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const languages = {
    "zh-TW": "繁體中文",
    "zh-CN": "简体中文",
    "en": "English",
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (lang, index) => {
    i18n.changeLanguage(lang);
    setSelectedIndex(index);
    setAnchorEl(null);
    window.localStorage.setItem("language", lang);
  };

  return (
    <Box>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={`${classes.languageButton}`}
      >
        {languages[i18n.language]}
      </Button>
      <Menu
        className={`${classes.menu}`}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(languages).map((v, i) => (
          <MenuItem
            key={i}
            selected={i === selectedIndex}
            onClick={() => handleMenuItemClick(v, i)}
          >
            {languages[v]}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
