import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loading: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background: "#000000",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "32px",
  },
}));

export default function Loading() {
  const classes = useStyles();
  return (
    <div className={`${classes.loading}`}>
      {/* <span className="nft-text">Loading...</span> */}
      <CircularProgress disableShrink style={{ color: "#5CC6FF" }} />
    </div>
  );
}
